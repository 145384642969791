<template>
    <div class="tv-channels pl-9 pr-9 header-clear">
        <v-row class="pb-9 wrapper">
            <h2 class="mt-12">Включени канали</h2>
        </v-row>
        <v-row class="wrapper pb-12">
            <h3 class="mb-4">Стандарт ТВ Пакет</h3>
              <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Включени канали
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div
                            v-for="channel in channelsListStandard"
                            :key="channel.id"
                            class="channel-container mt-4 mb-4 text-center"
                        >
                            <h2 class="dialog-text mb-4">{{ channel.title }}</h2>
                            <img class="dialog-image" :src="`/` + channel.url" :alt="channel.title + ' image'"/>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-row class="wrapper pb-12">
            <h3 class="mb-4">Разширен ТВ Пакет</h3>
              <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Включени канали
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div
                            v-for="channel in channelsListExtended"
                            :key="channel.id"
                            class="channel-container mt-4 mb-4 text-center"
                        >
                            <h2 class="dialog-text mb-4">{{ channel.title }}</h2>
                            <img class="dialog-image" :src="`/` + channel.url" :alt="channel.title + ' image'"/>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-row class="wrapper pb-12">
            <h3 class="mb-4">Разширен Плюс ТВ Пакет</h3>
              <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        Включени канали
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div
                            v-for="channel in channelsListExtendedPlus"
                            :key="channel.id"
                            class="channel-container mt-4 mb-4 text-center"
                        >
                            <h2 class="dialog-text mb-4">{{ channel.title }}</h2>
                            <img class="dialog-image" :src="`/` + channel.url" :alt="channel.title + ' image'"/>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-row class="wrapper flex-column flex-wrap pb-12">
            <h3 class="mb-4">Допълнителни канали</h3>
            <v-row class="d-flex additional-column">
                <v-col  
                    v-for="card in additionalChannels"
                    :key="card.id"
                    >
                    <v-card>
                        <div class="additional-channels pt-4 pl-4">
                            <img :src="card.url" :alt="card.title">
                        </div>
                        <v-card-text>{{ card.title }}</v-card-text>
                        <v-card-title class="additional-title">{{ card.price}} <span class="pl-2">/ месец</span></v-card-title>
                    </v-card>
                </v-col>
            </v-row>
        </v-row>
    </div>
</template>
<style lang="scss">
    .additional-channels {
        img {
            height: 50px;
            width: 120px;
            object-fit: contain;
        }
    }
    @media screen and(max-width: 768px) {
        .additional-title {
            font-size: 1rem !important;
        }
    }
    @media screen and(max-width: 600px) {
        .additional-column {
            flex-direction: column;
        }
    }
</style>
<script>
export default {
    data: () => ({
        additionalChannels: [
            { id: 1, title: 'DIEMAXTRA', price: '15,00 лева', url: '/diemaxtra_logo.png'},
            { id: 2, title: 'MAXSPORT PLUS', price: '10,00 лева', url: '/max_sport_logo.png'},
            { id: 3, title: '7/8 TV', price: '0,78 лева', url: '/78_logo.png'},
        ],
        channelsListStandard: [
          { id: 1, title: 'BNT1', url: 'BNT1.png' },
          { id: 2, title: 'BTV', url: 'BTV-logo.png' },
          { id: 3, title: 'Nova', url: 'Nova.png' },
          { id: 4, title: 'BTV Action', url: 'BTV-Action.jpg' },
          { id: 5, title: 'BTV Comedy', url: 'Btv-comedy.png' },
          { id: 6, title: 'BTV Cinema', url: 'BTV_Cinema_2016_logo.png' },
          { id: 8, title: 'BTV Lady', url: 'BTV_Lady_2016_logo.png' },
          { id: 9, title: 'RING.BG', url: 'Ring-bg.png' },
          { id: 21, title: 'Bulgaria On Air', url: 'Bulgariaonair.png' },
          { id: 23, title: 'Bloomberg TV', url: 'bloomberg.png' },
          { id: 28, title: 'BNT 2', url: 'BNT2.png' },
          { id: 29, title: 'BNT 4', url: 'BNT4.png' },
          { id: 34, title: 'TV Evropa', url: 'tv-evropa.jpg' },
          { id: 36, title: 'BG Dnes', url: 'bg-dnes.jpg' },
          { id: 37, title: 'EVROKOM', url: 'evrokom.png' },
          { id: 46, title: 'Super Toons', url: 'super-toons.jpg' },
          { id: 50, title: 'Discovery Channel', url: 'discoveryad1.png' },
          { id: 51, title: 'ID', url: 'id.png' },
          { id: 52, title: 'TLC', url: 'tlc.png' },
          { id: 55, title: 'DW', url: 'dw.png' }, 
          { id: 56, title: 'Eurosport 1', url: 'euro-sport.png' }, 
          { id: 57, title: 'Eurosport 2', url: 'euro-sport-2.jpg' },
          { id: 58, title: 'Food Network', url: 'food.png' }, 
          { id: 59, title: 'France 24', url: 'france-24.png' }, 
          // { id: 60, title: 'Russia Today HD', url: 'russia-today.png' }, 
          { id: 61, title: 'Travel Channel', url: 'travel.png' },
          { id: 62, title: 'TV5 Monde', url: 'tv5.png' }, 
          { id: 63, title: 'Алфа ТВ', url: 'alfa.jpg' }, 
          { id: 64, title: 'БСТВ', url: 'bstv.png' },
          { id: 65, title: 'Българе', url: 'bulgare.jpg' },
          { id: 66, title: 'България 24', url: 'bulgaria24.jpg' },
          { id: 67, title: 'ВТК', url: 'vtk.png' }, 
          { id: 68, title: 'Планета 4К', url: 'planeta4k.png' },
          { id: 69, title: 'Планета HD', url: 'planetahd.png' },
          { id: 70, title: 'Планета Фолк', url: 'planeta-folk.jpg' },
          { id: 71, title: 'ППТВ', url: 'pptv-logo.png' }, 
          { id: 72, title: 'СИТИ ТВ', url: 'city.jpg' },
          { id: 73, title: 'DTX', url: 'dtx-logo.png'},
          { id: 74, title: 'HGTV', url: 'hgtv-logo.png'},
          { id: 75, title: 'Discovery Science', url: 'discovery-science-2017-logo.png'}
        ],
        channelsListExtended: [
          { id: 1, title: 'BNT1', url: 'BNT1.png' },
          { id: 2, title: 'BTV', url: 'BTV-logo.png' },
          { id: 3, title: 'Nova', url: 'Nova.png' },
          { id: 4, title: 'BTV Action', url: 'BTV-Action.jpg' },
          { id: 5, title: 'BTV Comedy', url: 'Btv-comedy.png' },
          { id: 6, title: 'BTV Cinema', url: 'BTV_Cinema_2016_logo.png' },
          { id: 7, title: 'Kino Nova', url: 'KinoNova.png' },
          { id: 8, title: 'BTV Lady', url: 'BTV_Lady_2016_logo.png' },
          { id: 9, title: 'RING.BG', url: 'Ring-bg.png' },
          { id: 10,  title: 'Diema', url: 'Diema_logo.png' },
          { id: 11,  title: 'Diema Family', url: 'DiemaFamily_2019.png' },
          { id: 12,  title: 'Nova Sport', url: 'NovaSport.png' },
          { id: 14,  title: 'FOX', url: 'FOX.png' },
          { id: 15,  title: 'Fox Life', url: 'fox-life.jpg' },
          { id: 16,  title: 'Fox Crime', url: 'fox-crime.jpg' },
          { id: 21,  title: 'Bulgaria On Air', url: 'Bulgariaonair.png' },
          { id: 23,  title: 'Bloomberg TV', url: 'bloomberg.png' },
          { id: 24,  title: 'National Geographic', url: 'nat-geo.png' },
          { id: 26,  title: 'Nat Geo Wild', url: 'nat-geo-wild.png' },
          { id: 28, title: 'BNT 2', url: 'BNT2.png' },
          { id: 29, title: 'BNT 4', url: 'BNT4.png' },
          { id: 36, title: 'BG Dnes', url: 'bg-dnes.jpg' },
          { id: 37, title: 'EVROKOM', url: 'evrokom.png' },
          { id: 41, title: 'Disney Channel', url: 'disney.png' },
          { id: 46, title: 'Super Toons', url: 'super-toons.jpg' },
          { id: 48, title: 'Карусель', url: 'karusel.png' },
          { id: 49, title: 'LOLLY KIDS', url: 'lolly-kids.png' },
          { id: 50, title: 'Discovery Channel', url: 'discoveryad1.png' },
          { id: 51, title: 'ID', url: 'id.png' },
          { id: 52, title: 'TLC', url: 'tlc.png' },
          { id: 53, title: 'Animal Planet', url: 'animal-planet.jpg' },
          { id: 54, title: 'History', url: 'history.jpg' },
          { id: 55, title: 'DW', url: 'dw.png' }, 
          { id: 56, title: 'Eurosport 1', url: 'euro-sport.png' }, 
          { id: 57, title: 'Eurosport 2', url: 'euro-sport-2.jpg' },
          { id: 58, title: 'Food Network', url: 'food.png' }, 
          { id: 59, title: 'France 24', url: 'france-24.png' }, 
          // { id: 60, title: 'Russia Today HD', url: 'russia-today.png' }, 
          { id: 61, title: 'Travel Channel', url: 'travel.png' },
          { id: 62, title: 'TV5 Monde', url: 'tv5.png' }, 
          { id: 63, title: 'Алфа ТВ', url: 'alfa.jpg' }, 
          { id: 64, title: 'БСТВ', url: 'bstv.png' },
          { id: 65, title: 'Българе', url: 'bulgare.jpg' },
          { id: 66, title: 'България 24', url: 'bulgaria24.jpg' },
          { id: 67, title: 'ВТК', url: 'vtk.png' }, 
          { id: 68, title: 'Планета 4К', url: 'planeta4k.png' },
          { id: 69, title: 'Планета HD', url: 'planetahd.png' },
          { id: 70, title: 'Планета Фолк', url: 'planeta-folk.jpg' },
          { id: 71, title: 'ППТВ', url: 'pptv-logo.png' }, 
          { id: 72, title: 'СИТИ ТВ', url: 'city.jpg' },
        //   { id: 74, title: 'BLACKDIAMONDZ', url: 'blackdiamonds.png' },
          { id: 75, title: 'BOX TV', url: 'box.png' },
          { id: 76, title: 'CCTV-4', url: 'cctv4.png' },
          // { id: 77, title: 'CGTN', url: 'cgtn.png' },
          // { id: 78, title: 'CGTN -  Español', url: 'cgtn-espanol.png' },
          // { id: 79, title: 'CGTN -  Français', url: 'cgtn-francais.png' },
          // { id: 80, title: 'CGTN -  Русский', url: 'cgtn-russian.png' },
          // { id: 81, title: 'CGTN -  العرب', url: 'cgtn-arabic.png' },
          // { id: 82, title: 'CGTN–Documentary', url: 'cgtn-documentary.png' },
        //   { id: 83, title: 'CHILAYO', url: 'Chillayo_logo_last.png' },
        //   { id: 84, title: 'CUBAYO', url: 'cubayo.png' },
          { id: 85, title: 'DSTV', url: 'dstv.jpg' },
          { id: 86, title: 'EDGE Sport', url: 'edgesport.png' },
        //   { id: 87, title: 'FLAME', url: 'flame-tv.png' },
        //   { id: 88, title: 'H&WELLNESS', url: 'wellness.png' },
          // { id: 89, title: 'HOMIE‘S', url: '' },
          { id: 90, title: 'Insight HD', url: 'insight.png' },
        //   { id: 92, title: 'LUXE &LIFE', url: 'luxe.jpg' },
          { id: 93, title: 'NOVA HD', url: 'nova-hd.jpg' },
          { id: 94, title: 'Nova Sport HD', url: 'nova-sport-hd.jpg' }, 
        //   { id: 95, title: 'PARTY‘S', url: 'partys.png' },
        //   { id: 96, title: 'PET & PAL', url: 'pet-pal.png' },
          { id: 97, title: 'Public TV of Armenia', url: 'tv-armenia.jpg' }, 
          { id: 98, title: 'Roma TV', url: 'roma_tv.png' }, 
          { id: 99, title: 'Roma TV HD', url: 'roma_tv.png' }, 
          // { id: 100, title: 'Russia 24', url: 'russia-24.png' }, 
        //   { id: 101, title: 'SMILIE', url: 'smilie.png' },
        //   { id: 102, title: 'SPEED FACTOR', url: 'speed-factor.png' },
        //   { id: 103, title: 'SPORTIFY', url: '' },
          { id: 104, title: 'This is Bulgaria', url: '' },
          { id: 106, title: 'Travel XP', url: 'travelxp.png' },
          { id: 107, title: 'Wness TV', url: 'wness.png' },
        //   { id: 108, title: 'WOW', url: 'wow.jpg' },
          { id: 109, title: 'Агро ТВ', url: 'agro-tv.png' },
          { id: 110, title: 'Восьмой канал', url: '8.png' }, 
          { id: 111, title: 'Время', url: 'vremya.png' }, 
          { id: 112, title: 'Детский мир', url: 'detski-mir.jpg' }, 
          { id: 113, title: 'Дом кино', url: 'dom-kino.png' }, 
          { id: 114, title: 'Дом кино премиум', url: 'dom-kino-premium.png' }, 
          { id: 115, title: 'Музъiка', url: '' }, 
          { id: 116, title: 'Настоящее время', url: 'nastoyashte-vremya.png' }, 
          { id: 117, title: 'Национ. Патр. ТВ', url: '' }, 
          { id: 118, title: 'Наше любимое кино', url: 'nashe-kino.png' }, 
          { id: 119, title: 'Охота и Рьiбалка', url: 'ohota.png' }, 
          { id: 120, title: '   ', url: '   ' }, 
          // { id: 121, title: 'РТР Планета', url: 'rtr.png' }, 
          // { id: 122, title: 'ТВ Центр', url: 'tvcentr.png' }, 
          { id: 123, title: 'ТВ1', url: 'tv1.png' },
          { id: 124, title: 'Тянков Ориент', url: 'orient.png' },
          { id: 125, title: 'Тянков ТВ', url: 'tiankov.png' },
          { id: 126, title: 'Фолклор ТВ', url: 'folklor.jpg' },
          { id: 127, title: 'Шансон ТВ', url: 'shanson.png' },
          { id: 128, title: 'DTX', url: 'dtx-logo.png'},
          { id: 129, title: 'HGTV', url: 'hgtv-logo.png'},
          { id: 130, title: 'Discovery Science', url: 'discovery-science-2017-logo.png'} 
        ],
        channelsListExtendedPlus: [
          { id: 1, title: 'BNT1', url: 'BNT1.png' },
          { id: 2, title: 'BTV', url: 'BTV-logo.png' },
          { id: 3, title: 'Nova', url: 'Nova.png' },
          { id: 4, title: 'BTV Action', url: 'BTV-Action.jpg' },
          { id: 5, title: 'BTV Comedy', url: 'Btv-comedy.png' },
          { id: 6, title: 'BTV Cinema', url: 'BTV_Cinema_2016_logo.png' },
          { id: 7, title: 'Kino Nova', url: 'KinoNova.png' },
          { id: 8, title: 'BTV Lady', url: 'BTV_Lady_2016_logo.png' },
          { id: 9, title: 'RING.BG', url: 'Ring-bg.png' },
          { id: 10,  title: 'Diema', url: 'Diema_logo.png' },
          { id: 11,  title: 'Diema Family', url: 'DiemaFamily_2019.png' },
          { id: 12,  title: 'Nova Sport', url: 'NovaSport.png' },
          { id: 13,  title: 'BTN 1 HD', url: 'BNT1.png' },
          { id: 14,  title: 'FOX', url: 'FOX.png' },
          { id: 15,  title: 'Fox Life', url: 'fox-life.jpg' },
          { id: 16,  title: 'Fox Crime', url: 'fox-crime.jpg' },
          { id: 17,  title: 'FOX HD', url: 'fox-hd.png' },
          { id: 18,  title: 'Fox Life HD', url: 'FOX_life_HD.png' },
          { id: 19,  title: 'Fox Crime HD', url: 'fox-crime.jpg' },
          { id: 20,  title: 'TV1000', url: 'tv-1000.png' },
          { id: 21,  title: 'Bulgaria On Air', url: 'Bulgariaonair.png' },
          { id: 22,  title: 'Bloomberg TV Bulgaria HD', url: 'bloombergbulgaria.png' },
          { id: 23,  title: 'Bloomberg TV', url: 'bloomberg.png' },
          { id: 24,  title: 'National Geographic', url: 'nat-geo.png' },
          { id: 25,  title: 'National Geographic HD', url: 'nat-geo-hd.png' },
          { id: 26,  title: 'Nat Geo Wild', url: 'nat-geo-wild.png' },
          { id: 27,  title: 'Nat Geo Wild HD', url: 'nat-geo-wild-hd.png' },
          { id: 28, title: 'BNT 2', url: 'BNT2.png' },
          { id: 29, title: 'BNT 4', url: 'BNT4.png' },
          { id: 30, title: 'BNT 2 HD', url: 'BNT2.png' },
          { id: 31, title: 'BNT 3 HD', url: 'BNT3.png' },
          { id: 32, title: 'BNT 4 HD', url: 'BNT4.png' },
          { id: 33, title: 'Nova News', url: 'nova-news.png' },
          { id: 34, title: 'TV Evropa', url: 'tv-evropa.jpg' },
          { id: 35, title: 'The World', url: 'world-channel.png' },
          { id: 36, title: 'BG Dnes', url: 'bg-dnes.jpg' },
          { id: 37, title: 'EVROKOM', url: 'evrokom.png' },
          { id: 38, title: '24 Kitchen', url: '24-kitchen.png' },
          { id: 39, title: '24 Kitchen HD', url: '24-kitchen.png' },
          { id: 40, title: 'Baby TV', url: 'baby-tv.jpg' },
          { id: 41, title: 'Disney Channel', url: 'disney.png' },
          { id: 42, title: 'Disney Jr.', url: 'disney-junior.png' },
          { id: 43, title: 'Nickelodeon', url: 'nickelodeon.png' },
          { id: 44, title: 'Nick Jr.', url: 'nick-jr.png' },
          { id: 45, title: 'ECTV for Kids HD', url: 'ectv-kids.png' },
          { id: 46, title: 'Super Toons', url: 'super-toons.jpg' },
          { id: 47, title: 'Nicktoons', url: 'nicktoons.png' },
          { id: 48, title: 'Карусель', url: 'karusel.png' },
          { id: 49, title: 'LOLLY KIDS', url: 'lolly-kids.png' },
          { id: 50, title: 'Discovery Channel', url: 'discoveryad1.png' },
          { id: 51, title: 'ID', url: 'id.png' },
          { id: 52, title: 'TLC', url: 'tlc.png' },
          { id: 53, title: 'Animal Planet', url: 'animal-planet.jpg' },
          { id: 54, title: 'History', url: 'history.jpg' },
          { id: 55, title: 'DW', url: 'dw.png' }, 
          { id: 56, title: 'Eurosport 1', url: 'euro-sport.png' }, 
          { id: 57, title: 'Eurosport 2', url: 'euro-sport-2.jpg' },
          { id: 58, title: 'Food Network', url: 'food.png' }, 
          { id: 59, title: 'France 24', url: 'france-24.png' }, 
          // { id: 60, title: 'Russia Today HD', url: 'russia-today.png' }, 
          { id: 61, title: 'Travel Channel', url: 'travel.png' },
          { id: 62, title: 'TV5 Monde', url: 'tv5.png' }, 
          { id: 63, title: 'Алфа ТВ', url: 'alfa.jpg' }, 
          { id: 64, title: 'БСТВ', url: 'bstv.png' },
          { id: 65, title: 'Българе', url: 'bulgare.jpg' },
          { id: 66, title: 'България 24', url: 'bulgaria24.jpg' },
          { id: 67, title: 'ВТК', url: 'vtk.png' }, 
          { id: 68, title: 'Планета 4К', url: 'planeta4k.png' },
          { id: 69, title: 'Планета HD', url: 'planetahd.png' },
          { id: 70, title: 'Планета Фолк', url: 'planeta-folk.jpg' },
          { id: 71, title: 'ППТВ', url: 'pptv-logo.png' }, 
          { id: 72, title: 'СИТИ ТВ', url: 'city.jpg' },
        //   { id: 74, title: 'BLACKDIAMONDZ', url: 'blackdiamonds.png' },
          { id: 75, title: 'BOX TV', url: 'box.png' },
          { id: 76, title: 'CCTV-4', url: 'cctv4.png' },
          // { id: 77, title: 'CGTN', url: 'cgtn.png' },
          // { id: 78, title: 'CGTN -  Español', url: 'cgtn-espanol.png' },
          // { id: 79, title: 'CGTN -  Français', url: 'cgtn-francais.png' },
          // { id: 80, title: 'CGTN -  Русский', url: 'cgtn-russian.png' },
          // { id: 81, title: 'CGTN -  العرب', url: 'cgtn-arabic.png' },
          // { id: 82, title: 'CGTN–Documentary', url: 'cgtn-documentary.png' },
        //   { id: 83, title: 'CHILAYO', url: 'Chillayo_logo_last.png' },
        //   { id: 84, title: 'CUBAYO', url: 'cubayo.png' },
          { id: 85, title: 'DSTV', url: 'dstv.jpg' },
          { id: 86, title: 'EDGE Sport', url: 'edgesport.png' },
        //   { id: 87, title: 'FLAME', url: 'flame-tv.png' },
        //   { id: 88, title: 'H&WELLNESS', url: 'wellness.png' },
          // { id: 89, title: 'HOMIE‘S', url: '' },
          { id: 90, title: 'Insight HD', url: 'insight.png' },
        //   { id: 92, title: 'LUXE &LIFE', url: 'luxe.jpg' },
          { id: 93, title: 'NOVA HD', url: 'nova-hd.jpg' },
          { id: 94, title: 'Nova Sport HD', url: 'nova-sport-hd.jpg' }, 
        //   { id: 95, title: 'PARTY‘S', url: 'partys.png' },
        //   { id: 96, title: 'PET & PAL', url: 'pet-pal.png' },
          { id: 97, title: 'Public TV of Armenia', url: 'tv-armenia.jpg' }, 
          { id: 98, title: 'Roma TV', url: 'roma_tv.png' }, 
          { id: 99, title: 'Roma TV HD', url: 'roma_tv.png' }, 
          // { id: 100, title: 'Russia 24', url: 'russia-24.png' }, 
        //   { id: 101, title: 'SMILIE', url: 'smilie.png' },
        //   { id: 102, title: 'SPEED FACTOR', url: 'speed-factor.png' },
        //   { id: 103, title: 'SPORTIFY', url: '' },
          { id: 104, title: 'This is Bulgaria', url: '' },
          { id: 106, title: 'Travel XP', url: 'travelxp.png' },
          { id: 107, title: 'Wness TV', url: 'wness.png' },
        //   { id: 108, title: 'WOW', url: 'wow.jpg' },
          { id: 109, title: 'Агро ТВ', url: 'agro-tv.png' },
          { id: 110, title: 'Восьмой канал', url: '8.png' }, 
          { id: 111, title: 'Время', url: 'vremya.png' }, 
          { id: 112, title: 'Детский мир', url: 'detski-mir.jpg' }, 
          { id: 113, title: 'Дом кино', url: 'dom-kino.png' }, 
          { id: 114, title: 'Дом кино премиум', url: 'dom-kino-premium.png' }, 
          { id: 115, title: 'Музъiка', url: '' }, 
          { id: 116, title: 'Настоящее время', url: 'nastoyashte-vremya.png' }, 
          { id: 117, title: 'Национ. Патр. ТВ', url: '' }, 
          { id: 118, title: 'Наше любимое кино', url: 'nashe-kino.png' }, 
          { id: 119, title: 'Охота и Рьiбалка', url: 'ohota.png' }, 
          { id: 120, title: '   ', url: '   ' }, 
          // { id: 121, title: 'РТР Планета', url: 'rtr.png' }, 
          // { id: 122, title: 'ТВ Центр', url: 'tvcentr.png' }, 
          { id: 123, title: 'ТВ1', url: 'tv1.png' },
          { id: 124, title: 'Тянков Ориент', url: 'orient.png' },
          { id: 125, title: 'Тянков ТВ', url: 'tiankov.png' },
          { id: 126, title: 'Фолклор ТВ', url: 'folklor.jpg' },
          { id: 127, title: 'Шансон ТВ', url: 'shanson.png' }, 
          { id: 128, title: '3sat HD', url: '3sat.png' },
          { id: 129, title: 'AI Jazeera', url: 'al.png' },
          { id: 130, title: 'Animal Planet HD', url: 'aphd.png' },
          { id: 131, title: 'ARD HD', url: 'ard.png' },
          { id: 132, title: 'Arirang TV', url: 'arirang.png' },
          { id: 133, title: 'Arte', url: 'arte.png' },
          { id: 134, title: 'Balkanika', url: 'balkanika.png' },
          { id: 135, title: 'Balkanika HD', url: 'balkanika.png' },
          { id: 136, title: 'BG Music Channel', url: 'bgmusic.jpg' },
          { id: 137, title: 'BG Music Channel HD', url: 'bgmusic.jpg' },
          { id: 138, title: 'bTV Action HD', url: 'actionhd.jpg' },
          { id: 139, title: 'bTV Cinema HD', url: 'BTV_Cinema_2016_logo.png' },
          { id: 140, title: 'bTV Comedy HD', url: 'Btv-comedy.png' },
          { id: 141, title: 'bTV HD', url: 'btvhd.jpg' },
          { id: 142, title: 'Bulgaria On Air HD', url: 'Bulgariaonair.png' },
          { id: 143, title: 'CentoxCento', url: 'cento.png' },
          { id: 144, title: 'Discovery Channel HD', url: 'discovery-hd.png' },
          { id: 145, title: 'DM Sat', url: 'dm_sat.png' },
          { id: 146, title: 'Eurosport 1 HD', url: 'euro-sport-hd.png' },
          { id: 147, title: 'Eurosport 2 HD', url: 'euro-sport-2-hd.png' },
          { id: 148, title: 'Fen Folk', url: 'fen_folk.png' },
          { id: 149, title: 'Fen TV', url: 'fen.png' },
          { id: 150, title: 'Fen TV HD', url: 'fen-hd.png' },
          { id: 151, title: 'Food Network HD', url: 'food-hd.png' },
          { id: 152, title: 'France 2', url: 'france-2.png' },
          { id: 153, title: 'France 3', url: 'france-3.png' },
          { id: 154, title: 'Hit Mix Music', url: 'hit.png' },
          { id: 155, title: 'futureONE HD', url: 'FutureOne.png' },
          { id: 156, title: 'newsONE HD', url: 'newsOne.png' },
          { id: 157, title: 'ID HD', url: 'id-hd.png' },
          { id: 158, title: 'KCN 2 Music', url: 'kcn-music2.png' },
          { id: 159, title: 'KHL', url: 'khl.png' },
          { id: 160, title: 'KHL HD', url: 'khl-hd.png' },
          { id: 161, title: 'KiKa HD', url: 'kika_hd.png' },
          { id: 162, title: 'Magic TV', url: 'magic.jpg' },
          { id: 163, title: 'Movistar', url: 'moviestar-logo.png' },
          { id: 164, title: 'MTV', url: 'mtv.jpg' },
          { id: 165, title: 'MTV 80’s', url: 'mtv-80.jpg' },
          { id: 166, title: 'MTV 90’s', url: 'mtv-90.png' },
          { id: 167, title: 'MTV Hits', url: 'mtv-hits.png' },
          { id: 168, title: 'PassionXXX', url: 'passion.jpg' },
          { id: 169, title: 'PassionXXX 2', url: 'passion.jpg' },
          { id: 170, title: 'Penthouse Gold', url: 'pent-gold.png' },
          { id: 171, title: 'Penthouse Passion', url: 'pent-passion.png' },
          { id: 172, title: 'Penthouse Quickies', url: 'pent-quickies.png' },
          { id: 173, title: 'Pinko Club', url: 'pinko.png' },
          { id: 174, title: 'Pro 7', url: 'pro7.png' },
          { id: 175, title: 'RAI DUE', url: 'rai2.png' },
          { id: 176, title: 'RAI UNO', url: 'rai1.png' },
          { id: 177, title: 'RING.BG HD', url: 'ringhd.png' },
          { id: 178, title: 'RTVi', url: 'rtvi.png' },
          // { id: 179, title: 'Russia Today Docu HD', url: 'rtd-d.png' },
          { id: 180, title: 'Sat1', url: 'sat1.png' },
          { id: 181, title: 'The Voice', url: 'thevoice.png' },
          { id: 182, title: 'TLC HD', url: 'tlchd.png' },
          { id: 183, title: 'Travel Channel HD', url: 'travelHD.png' },
          { id: 184, title: 'TRT', url: 'trt.jpg' },
          { id: 185, title: 'TVE', url: 'tve.png' },
          { id: 186, title: 'UATV', url: 'uatv.jpg' },
          { id: 187, title: 'VH1/ MTV 00’s', url: 'mtv-00s.png' },
          { id: 188, title: 'Viasat Explore', url: 'viasat.png' },
          { id: 189, title: 'Viasat Explore HD', url: 'viasathd.png' },
          { id: 190, title: 'Viasat History', url: 'viasat-h.jpg' },
          { id: 191, title: 'Viasat History HD', url: 'viasathistoryhd.png' },
          { id: 192, title: 'Viasat Nature', url: 'Viasat_Nature.png' },
          { id: 193, title: 'Viasat Nature HD', url: 'viasatnaturehd.png' },
          { id: 194, title: 'ZDF HD', url: 'zdf.png' },
          { id: 195, title: 'Българе HD', url: 'bulgare.jpg' },
          { id: 196, title: 'Мир Сериала', url: 'Mir_seriala.png' },
          { id: 197, title: '   ', url: '   ' },
          { id: 198, title: 'Телекафе', url: 'telekafe.png' },
          { id: 199, title: 'Фолклор ТВ HD', url: 'folklor.jpg' },
          { id: 200, title: 'DTX', url: 'dtx-logo.png'},
          { id: 201, title: 'HGTV', url: 'hgtv-logo.png'},
          { id: 202, title: 'Discovery Science', url: 'discovery-science-2017-logo.png'} 
        ],
    })
}
</script>

<style lang="scss">
    .v-expansion-panel-content__wrap {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        flex: auto;
        max-width: unset;
        div {
            flex: 0 1 25%;
            @media screen and(max-width: 960px) {
                flex: 0 1 33.33%;
            }
            @media screen and(max-width: 450px) {
                flex: 0 1 50%;
            }
        }
    }
    .dialog-image {
        max-width: 70px;
        max-height: 70px;
        object-fit: cover;
        @media screen and(max-width: 450px) {
        max-width: 50px;
        max-height: 50px;
        }
  }
  .dialog-text {
    font-size: 24px;
    font-weight: 400;
    color: #000;
  }
</style>