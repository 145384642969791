<template>
  <div class="tv pl-9 pr-9 header-clear">
    <v-row class="pb-9 wrapper">
      <h2 class="mt-12">Телевизия</h2>
    </v-row>
    <v-row class="wrapper pb-12">
        <v-card
            class="mx-auto text-center mb-12 card card-tv is-visible"
            max-width="344"
            width="100%"
            color="#fff"
            v-for="cardTv in cardsTv"
            :key="cardTv.id"
        >
            <v-card-text>
            <h4 class="card-title about-card-title mt-3">{{ cardTv.title }}</h4>
            <div>
                <p class="display-3 black--text mb-0 mt-4">
                {{ cardTv.tv }}
                </p>
                <p class="black--text">{{ cardTv.sub }}</p>
            </div>
            <ul class="ul-text pa-0 mb-4 black--text">
                <li><v-icon
                right
                class="ml-0 mr-1"
                >
                mdi-check
            </v-icon>Ниски цени</li>
                <li><v-icon
                    right
                    class="ml-0 mr-1"
                >
                mdi-check
                </v-icon>Богат избор</li>
                <li><v-icon
                    right
                    class="ml-0 mr-1"
                >
                mdi-check
                </v-icon>Надеждна връзка</li>
            </ul>
            <div class="black--text">
                <div>Цена</div>
                <span class="display-1 black--text">{{ cardTv.price }}</span>
            </div>
            <div class="mt-6 mb-4">
                <router-link
                    outlined
                    color="#000"
                    class="text-center mb-6 tv-channels-link"
                    to="/services-tv-channels"
                >
                    Влючени Канали
                </router-link>
            </div>
            </v-card-text>
        </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
    data: () => ({
        cardsTv: [
            { id: '4', title: 'Стандарт - ТВ', net: '50', tv: '35 +', sub: 'канала', subNet: 'Mbit/s', price: '12,00 лв' },
            { id: '5', title: 'Разширен - ТВ', net: '80', tv: '100 +', sub: 'канала', subNet: 'Mbit/s', price: '15,00 лв' },
            { id: '6', title: 'Разширен Плюс - ТВ', net: '100', tv: '190 +', sub: 'канала', subNet: 'Mbit/s', price: '18,00 лв' },
        ],
    })
}
</script>

<style lang="scss">
    .about-card-title {
        color: #000 !important;
    }
    .tv-channels-link {
        font-size: 0.875rem;
        font-weight: bold;
        color: #000 !important;
        text-transform: uppercase;
        text-decoration: none;
        padding: 10px 14px;
        border: 1px solid #000;
        border-radius: 4px;
        margin-bottom: 20px;
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
</style>